<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <keep-alive :include="['home','navigation']">
        <router-view></router-view>
      </keep-alive>
      <!-- <router-view v-else/> -->
      <!-- <div class="show_msg">
        <div class="show_msg_content">
          <div class="show_msg_content_title">提示</div>
          <div class="show_msg_content_msg">文件超出限制大小，请调整后重试</div>
        </div>
      </div> -->
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import { mapActions } from "vuex";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  name: "App",
  data() {
    return {
      zh_CN,
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions(["getUserInfo"]),
  },
};
</script>

<style  lang="less">
.show_msg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  .show_msg_content {
    width: 400px;
    padding: 30px 70px;
    height: auto;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: inline-block;
    opacity: 1;
    right: 0;
    top: 0;
    z-index: 1000000000;
    margin: auto;
    .show_msg_content_msg {
      font-size: 14px;
      color: #81878c;
      text-align: center;
    }
    .show_msg_content_title {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 25px;
      text-align: center;
    }
  }
}
.size {
  width: 100%;
  height: 100%;
  background: #fff;
}
html,
body {
  .size;
  margin: 0;
  padding: 0;
}
#app {
  .size;
  min-height: 100%;
  height: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
