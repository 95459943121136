import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './TUIRoom/locales/';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/css/index.less';
import footers from '@/components/footer/index';
import list from '@/components/list/index';
import tab from '@/components/tab/index';
import noneMsg from '@/components/noneMsg/index';
import api from '@/api/index';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
});
Vue.component(tab.name, tab);
Vue.component(noneMsg.name, noneMsg);
Vue.component(footers.name, footers);
Vue.prototype.apis = api;
Vue.component(list.name, list);
Vue.config.productionTip = false;
Vue.use(Antd);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(ElementUI);
Vue.use(i18n);

Vue.config.productionTip = false;

new Vue({
  pinia,
  // @ts-ignore
  router,
  store,
  render: h => h(App),
}).$mount('#app');