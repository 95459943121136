import Vue from 'vue';
import store from "@/store";
import VueRouter from 'vue-router';
import Home from '../views/home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home'),
    children: []
  },
  {
    path: '/TUIRoom',
    name: 'home',
    component: Home,
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Home,
  // },
  {
    path: '/room',
    name: 'room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/room.vue'),
  },
  {
    path: '/mian',
    name: 'mian',
    redirect: 'social',
    component: () => import('@/views/main'),
    children: [
      {
        path: '/qa',
        name: 'qa',
        component: () => import('@/views/about/qa.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/about.vue'),
      },
      {
        path: '/navigation',
        name: 'navigation',
        component: () => import('@/views/school/navigation'),
      },
      {
        path: '/semCompetition',
        name: 'semCompetition',
        component: () => import('@/views/semCompetition/index.vue'),
      },
      {
        path: '/composition',
        name: 'composition',
        component: () => import('@/views/semCompetition/composition.vue'),
      },
      {
        path: '/infomation',
        name: 'infomation',
        component: () => import('@/views/semCompetition/infomation.vue'),
      },
      {
        path: '/homejob',
        component: () => import('@/views/home'),
        children: [
          {
            path: '/',
            name: 'homejob',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/home/homeJob'),
          },
          {
            path: '/homejobDetail',
            name: 'homejobDetail',
            meta: {
              title: '职位详情',
            },
            component: () => import('@/views/home/homeDetail'),
          },
        ],
      },
      {
        path: '/social',
        name: 'social',
        redirect: 'socialJob',
        component: () => import('@/views/social'),
        children: [
          {
            path: '/socialJob',
            name: 'socialJob',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/social/socialJob'),
          },
          {
            path: '/socialDetail',
            name: 'socialDetail',
            meta: {
              title: '职位详情',
            },
            component: () => import('@/views/social/socialDetail'),
          },
        ],
      },
      {
        path: '/externalRecommend',
        name: 'externalRecommend',
        redirect: 'externalRecommendJob',
        component: () => import('@/views/externalRecommend'),
        children: [
          {
            path: '/externalRecommendJob',
            name: 'externalRecommendJob',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/externalRecommend/externalRecommendJob'),
          },
          {
            path: '/externalRecommendDetail',
            name: 'externalRecommendDetail',
            meta: {
              title: '职位详情',
            },
            component: () => import('@/views/externalRecommend/detail'),
          },
        ],
      },
      {
        path: '/trainee',
        component: () => import('@/views/trainee'),
        children: [
          {
            path: '/',
            name: 'trainee',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/trainee/traineeJob'),
          },
          {
            path: '/traineeDetail',
            name: 'traineeDetail',
            meta: {
              title: '职位详情',
            },
            component: () => import('@/views/trainee/traineeDetail'),
          },
        ],
      },
      {
        path: '/school',
        component: () => import('@/views/school'),
        children: [
          {
            path: '/',
            name: 'school',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/school/schoolJob'),
          },
          {
            path: '/schoolDetail',
            name: 'schoolDetail',
            meta: {
              title: '职位详情',
            },
            component: () => import('@/views/school/schoolDetail'),
          },
        ],
      },
      {
        path: '/offerReply',
        name: 'offerReply',
        component: () => import('@/views/offerReply'),
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/personal'),
      },
      {
        path: '/othersResume',
        name: 'othersResume',
        component: () => import('@/views/othersResume'),
      },
      {
        path: '/addOthersResume',
        name: 'addOthersResume',
        component: () => import('@/views/othersResume/addOthersResume'),
      },
      {
        path: '/upload-resume',
        name: 'uploadResume',
        component: () => import('@/views/uploadResume'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
      },
      {
        path: '/interview',
        name: 'interview',
        component: () => import('@/views/interview'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  if (to.name == 'personal') {
    if (store.state.hasGetInfo) {
      next()
    } else {
      store
        .dispatch('getUserInfo')
        .then((user) => {
      if (user.hasGetInfo) {
      next()
          } else {
      if (to.name === '/login') { // 如果是登录页面路径，就直接next()
              next()
            } else { // 不然就跳转到登录�
              next('/login')
            }
          }
        })
        .catch(() => {
          if (to.name === '/login') { // 如果是登录页面路径，就直接next()
            next()
          } else { // 不然就跳转到登录�
             next('/login')
          }
        })
    }
  } else {
    next()
  }
})
export default router;