import { render, staticRenderFns } from "./DialogH5.vue?vue&type=template&id=144db482"
import script from "./DialogH5.vue?vue&type=script&setup=true&lang=ts"
export * from "./DialogH5.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DialogH5.vue?vue&type=style&index=0&id=144db482&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports