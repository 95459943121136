import axios from '@/libs/api.request'

//获取验证码
const getMsgCode = (params) => {
  return axios.request({
    url: `/recruit/ats/portal/mobileCode`,
    method: 'get',
    params
  })
}
//获取校招职位城市列表
const schoolJobAddrs = (params) => {
  return axios.request({
    url: `/recruit/ats/portal/schoolJobAddrs`,
    method: 'get',
    params
  })
}
//获取社招职位城市列表
const socialJobAddrs = (params) => {
  return axios.request({
    url: `/recruit/ats/portal/socialJobAddrs`,
    method: 'get',
    params
  })
}
//获取图片验证码
const getImgCode = (params) => {
  return axios.request({
    url: `/recruit/ats/portal/getImgCode`,
    method: 'get',
    params
  })
}
//注册
const creatRegister = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/register`,
    method: 'post',
    data
  })
}
//反馈
const submitSuggest = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/submitSuggest`,
    method: 'post',
    data
  })
}
//登录
const login = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/login`,
    method: 'post',
    data
  })
}
//搜索职位
const searchJob = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/searchJob`,
    method: 'post',
    data
  })
}

//获取所有城市
const getCity = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/city`,
    method: 'post',
    data
  })
}
//获取所有字典详情
const getCategory = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/category`,
    method: 'post',
    data
  })
}
//获取首页背景图
const getTheme = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/theme`,
    method: 'post',
    data
  })
}
//社会招聘、实习生招聘列表
const societyJobList = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/societyJobList`,
    method: 'post',
    data
  })
}
//职位详情
const getJobDetail = (data, isExRec) => {
  let url = `/recruit/ats/portal/detail`
  if (isExRec === true) {
    // 外部推荐
    url = `/recruit/ats/external/detail`
  }
  return axios.request({
    url: url,
    method: 'post',
    data
  })
}
//收藏职位
const jobCollect = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/collect`,
    method: 'post',
    data
  })
}
//删除收藏职位
const jobCollectDelete = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/delete`,
    method: 'post',
    data
  })
}
//获取登录用户信息
const getUserInfo = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/getUserInfo`,
    method: 'post',
    data
  })
}
//我的收藏
const myCollection = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/myCollection`,
    method: 'post',
    data
  })
}
//获取校园招聘背景图
const getSchoolTheme = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/schoolTheme`,
    method: 'post',
    data
  })
}
//退出
const logout = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/logout`,
    method: 'post',
    data
  })
}
//申请
const jobApply = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/jobApply`,
    method: 'post',
    data
  })
}
//申请外推职位
const applyJob = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/applyJob`,
    method: 'post',
    data
  })
}
//我的申请列表
const myJobApply = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/myJobApply`,
    method: 'post',
    data
  })
}
//校招职位列表查询
const schoolJob = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/schoolJob`,
    method: 'post',
    data
  })
}

//重置密码
const reset = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/reset`,
    method: 'post',
    data
  })
}
//updateUserInfo
const updateUserInfo = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/updateUserInfo`,
    method: 'post',
    data
  })
}
const saveResume = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/saveResume`,
    method: 'post',
    data
  })
}
const getResume = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/getResume`,
    method: 'post',
    data
  })
}
const enterprise = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/enterprise`,
    method: 'post',
    data
  })
}
const aspiration = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/aspiration`,
    method: 'post',
    data
  })
}
const getLecture = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/lecture`,
    method: 'post',
    data
  })
}

const getSchoolSchedule= (data) => {
  return axios.request({
    url: `/recruit/ats/portal/openList`,
    method: 'get',
    params:data
  })
}

const getprevious = (data) => {
  return axios.request({
    url: `/recruit/ats/portal/previous`,
    method: 'post',
    data
  })
}

// 外部推荐列表
const externalRecommendJobList = (data) => {
  return axios.request({
    url: `/recruit/ats/external/externalJobList`,
    method: 'post',
    data
  })
}

// 外部推荐 - 获取转发详情
const getForwardDetail = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/jobLink`,
    method: 'post',
    data
  })
}

// 外部推荐 - 获取签署链接
const getSignLink = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/getSignLink`,
    method: 'post',
    data
  })
}

// 外部推荐 - 判断是否签署过协议
const isSignAgreement = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/isSignAgreement`,
    method: 'post',
    data
  })
}

// 外部推荐 - 保存(新增|编辑)他人简历
const saveOthersResume = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/saveOthersResume`,
    method: 'post',
    data
  })
}

// 外部推荐 - 获取他人简历
const getOthersResume = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/resumeList`,
    method: 'post',
    data
  })
}

// 外部推荐 - 推荐他人简历
const recommendResume = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/recommend`,
    method: 'post',
    data
  })
}

// 个人中心 - 更新用户信息
const updateInfo = (data) => {
  return axios.request({
    url: `/recruit/ats/portal2/modifyUserInfo`,
    method: 'post',
    data
  })
}

// 我的推荐列表
const myJobRec = (data) => {
  return axios.request({
    url: `/recruit/ats/externalLand/record`,
    method: 'post',
    data
  })
}

// 生成视频面试密钥
const genSig = (params) => {
  return axios.request({
    url: `/recruit/ats/recommend/genSig`,
    method: 'get',
    params
  })
}

// offer-验证
const offerVerifyCode = (data) => {
  return axios.request({
    url: `/recruit/api/offer/verifyCode`,
    method: 'post',
    data
  })
}

// offer-拒绝原因
const offerRefuseList = (data) => {
  return axios.request({
    url: `/recruit/api/offer/category`,
    method: 'post',
    data
  })
}

// offer-用户回复提交
const offerReply = (data) => {
  return axios.request({
    url: `/recruit/api/offer/reply`,
    method: 'post',
    data
  })
}

// 校园大赛 - 新增|编辑报名信息
const saveTeamInfo = (data) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-team/add`,
    method: 'post',
    data
  })
}

// 校园大赛 - 我的队伍(概览)详情
const getTeamInfo = () => {
  return axios.request({
    url: `/recruit/ats/rec-competition-team/getMySingleTeamDetail`,
    method: 'get'
  })
}

const getDetailInfo = () => {
  return axios.request({
    url: `/recruit/ats/rec-competition-project/getDetailInfo`,
    method: 'get'
  })
}

// 校园大赛 - 验证大赛
const checkCompetition = () => {
  return axios.request({
    url: `/recruit/ats/rec-competition/checkCompetition`,
    method: 'get'
  })
}

// 校园大赛 - 队伍详情
const getTeamDetailInfo = (id) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-team/getTeamDetail?teamId=${id}`,
    method: 'get'
  })
}

// 校园大赛 - 新增|编辑参赛作品
const saveProduction = (data) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-project/add`,
    method: 'post',
    data
  })
}

// 校园大赛 - 获取参赛主题
const getTopicOptions = () => {
  return axios.request({
    url: `/recruit/ats/rec-competition-project/listProjectCompetitionTheme`,
    method: 'post',
    header: {
      companyId: '0583d'
    }
  })
}

// 校园大赛 - 获取参赛赛题(项目)
const getTopicProject = (params) => {
  return axios.request({
    url: `/recruit/rec-competition-setting/getCompetition`,
    method: 'get',
    params
  })
}

// 校园大赛 - 获取作品详情
const getProductionDetail = (params) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-project/getDetail`,
    method: 'get',
    params
  })
}

// 校园大赛 - 获取赛事速递
const getCompetitionNews = (data) => {
  return axios.request({
    url: `/recruit//ats/sys-culture/list`,
    method: 'post',
    data
  })
}

// 校园大赛 - 校验队伍名称
const checkTeamName = (params) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-team/checkTeamName`,
    method: 'get',
    params
  })
}

const getInterviewInfo = (params) => {
  return axios.request({
    url: `/recruit/ats/recommend/getInterviewInfo`,
    method: 'get',
    params
  })
}

const genVideoError = (data) => {
  return axios.request({
    url: `/recruit/ats/recommend/genVideoError`,
    method: 'post',
    data
  })
}

const checkLatestProjectTime = (data) => {
  return axios.request({
    url: `/recruit/ats/rec-competition-project/checkLatestProjectTime`,
    method: 'get',
    data
  })
}

let api = {
  schoolJob,
  getMsgCode,
  creatRegister,
  login,
  searchJob,
  getCity,
  getTopicProject,
  getUserInfo,
  getCategory,
  societyJobList,
  getJobDetail,
  jobCollect,
  jobCollectDelete,
  getTheme,
  myCollection,
  getSignLink,
  applyJob,
  isSignAgreement,
  getSchoolTheme,
  logout,
  jobApply,
  myJobApply,
  reset,
  updateUserInfo,
  saveResume,
  getResume,
  enterprise,
  aspiration,
  getImgCode,
  submitSuggest,
  getLecture,
  getprevious,
  schoolJobAddrs,
  socialJobAddrs,
  externalRecommendJobList,
  getForwardDetail,
  saveOthersResume,
  getOthersResume,
  recommendResume,
  updateInfo,
  myJobRec,
  genSig,
  getSchoolSchedule,
  offerVerifyCode,
  offerRefuseList,
  offerReply,
  saveTeamInfo,
  getTeamInfo,
  getDetailInfo,
  checkCompetition,
  getTeamDetailInfo,
  saveProduction,
  getProductionDetail,
  getTopicOptions,
  getCompetitionNews,
  checkTeamName,
  getInterviewInfo,
  genVideoError,
  checkLatestProjectTime,
}
export default api
